/**
 * Tag post Template
 */
/* Import section */
import { graphql } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import Bannerwithcta from "../components/bannerwithcta"
import Herotype4 from "../components/herotype4"
import Layout from "../components/layout"
import Renderpost from "../components/renderpost"
import Seo from "../components/seo"
import "../styles/templates/postlist.scss"

const postsPerPage = 6
let arrayForHoldingPosts = []

/* Declaration function */
const TagPostTemplate = ({ data }) => {

  //Const data
  const dataTag = data.wpTag
  const dataPosts = data.allWpPost

  //Load More functionnality
  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(6)
  const [slugPost, setSlugPost] = useState(0)
  const prevSlugPostRef = useRef()
  const prevSlugPost = prevSlugPostRef.current
  const lengthPostsToShow = postsToShow.length
  const postsPosts = dataPosts.edges
  const lenghtPostsPosts = postsPosts.length
  var showLoadMore = (lenghtPostsPosts > postsPerPage && lenghtPostsPosts !== lengthPostsToShow) ? true : false

  if(!showLoadMore || prevSlugPost === undefined) {
    arrayForHoldingPosts = []
  }

  const loopWithSlice = (start, end) => {
    const slicedPosts = postsPosts.slice(start, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  useEffect(() => {
    loopWithSlice(0, postsPerPage)
    prevSlugPostRef.current = slugPost
  }, [])

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage)
    setNext(next + postsPerPage)
    setSlugPost(dataTag.name)
  }

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "blog"
  })

  const h1pageTag = "Hello Health’s Article Category: "+dataTag.name

  return (
    <Layout>
      <Seo
        title={`${dataTag.name} - Discover Hello Health’s Article Categories`}
        description={`${dataTag.name} - The Hello Health blog section contains various article categories ranging from health news to relevant product-focused topics.`}
      />

      {/* Hero section */}
      <section className="section-hero-static top-section">
        <Herotype4 h1title={h1pageTag}/>
      </section>

      {/* Section with loop on all Posts */}
      <section className="section-loop-posts">
        <Renderpost postsToRender={postsToShow} />
        {showLoadMore &&
        <div className="box-loadmore">
          <button onClick={handleShowMorePosts}>Load More Articles</button>
        </div>
        }
      </section>

      {/* Banner CTA */}
      <Bannerwithcta
        classBanner="section-banner-posts"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Export template informations */
export default TagPostTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
    query($id: Int!) {
      wpTag(databaseId: {eq: $id}) {
        name
        description
      }
      allWpPost(filter: {tags: {nodes: {elemMatch: {databaseId: {eq: $id}}}}}, sort: {fields: [date], order:DESC}) {
        edges {
          node {
            title
            slug
            date (
              formatString: "DD MMMM YYYY"
            )
            categories {
              nodes {
                name
                slug
              }
            }
            acfPostBlogNews {
              leadArticle
              imageArticle{
                localFile {
                  childImageSharp {
                    gatsbyImageData (layout: CONSTRAINED, width: 800, aspectRatio: 1.5)
                  }
                }
              }
            }
          }
        }
      }
      options: wpPage(slug: {eq: "options-website"}) {
        acfPageOptions {
          repeaterBannerCta {
            ctaLabelBanner
            ctaLinkBanner
            textBannerCta
            titleBannerCta
            topicBannerCta
          }
        }
      }
    }
`
