/**
 * Const with params element
 * Render Post component
 * Render the number of post of Block Article
 */
/* Import section */
import React from "react"
import Cardpost from "../components/cardpost"

/* Declaration function */
const Renderpost = ({ postsToRender }) => {
  return (
    <div className="wrap-loop-posts grid-loop-card-clickable">
      {postsToRender.map(({ node }) => (
        <Cardpost
          key={node.databaseId}
          titlePost={node.title}
          leadPost= {node.acfPostBlogNews.leadArticle}
          srcImgPost={node.acfPostBlogNews.imageArticle.localFile.childImageSharp.gatsbyImageData}
          slugPost={node.slug}
          typeSlugPost={node.categories.nodes[0].slug}
          datePost={node.date}
        />
      ))}
    </div>
  )
}

/* Export function */
export default Renderpost

