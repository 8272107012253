/**
 * Const with params element
 * Card post component
 * Display a card of summary Post post
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import TruncateText from "../components/truncatetext"

/* Declaration function */
const Cardpost = ({ titlePost, leadPost, srcImgPost, slugPost, datePost, typeSlugPost }) => {
  return (
    <div className="card-post card-clickable">
      <div className="box-image-card">
        <GatsbyImage image={srcImgPost} alt={`picture ${titlePost}`} />
      </div>
      <p className="type-date"><span>{datePost}</span></p>
      <h2><TruncateText str={titlePost} length="60" ending="..." /></h2>
      <div className="box-lead">
        <p><TruncateText str={leadPost} length="200" ending="..." /></p>
      </div>
      <div className="box-ctatext-card">
        <Ctabuttonwitharrow ctaLabel="Read More" ctaLink={`/${typeSlugPost}/${slugPost}`} specificStyle="text-arrow" colorStyle="white-format" />
      </div>
      <a href={`/${typeSlugPost}/${slugPost}/`} aria-label="Read More"><span className="span-cta-card" >Read More</span></a>
    </div>
  )
}

/* Export function */
export default Cardpost

